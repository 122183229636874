import React, { useState, useEffect } from 'react';
import { TextField, List, ListItem, ListItemText, Container, Typography, Box, Button, Grid, Link, Stepper, Step, StepLabel } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import Logo from '../../../assets/images/logo.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { theme, style, config } from '../../../config/settings.jsx';
import { steps } from '../../../config/steps.jsx';


  

 

  const Consented = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingRetry, setIsLoadingRetry] = useState(false);
    const navigate = useNavigate();
    const [statusMessage, setStatusMessage] = useState('Checking your bank connection and authorising accounts');
    const [pageTitle, setPageTitle] = useState('Connecting...');
    const location = useLocation();
    const cdrid = sessionStorage.getItem('cdrid');
    const [stepperStep, setStepperStep] = useState(1);
    const [reconsentRequired, setReconsentRequired] = useState(false);
    const [newConsentLink, setNewConsentLink] = useState('');
  
    const fetchJob = async (jobId) => {
      try {
        const response = await axios.post(
          'https://minerva.sipora.io/api/client/round-up/user/check-job',
          { jobId: jobId, cdrid: cdrid },
          { headers: { 'Content-Type': 'application/json', 'tenant': config.tenant, 'apikey': config.apiKey } }
        );
  
        const status = response.status;
        if (status === 200) {
          setStepperStep(2);
          setPageTitle('Success!');
          setStatusMessage('Successfully connected - we are now retrieving your accounts.');
          const retrieveAccounts = await axios.post(
            'https://minerva.sipora.io/api/client/round-up/user/retrieve-accounts',
            { cdrid: cdrid },
            { headers: { 'Content-Type': 'application/json', 'tenant': config.tenant, 'apikey': config.apiKey } }
          );
          if (retrieveAccounts.status === 200) {
            setStatusMessage('Accounts have successfully been retrieved.');
            navigate('/roundup-accounts');
          }
        } else if (status === 202) {
          setTimeout(() => fetchJob(jobId), 2000);
        } else {
          setReconsentRequired(true);
        }
      } catch (error) {
        setPageTitle('Uh Oh!');
        setStatusMessage('Failed to check in with your bank. Please try again.');
        setReconsentRequired(true);
      }
    };
  
    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const jobId = searchParams.get('jobId');
      localStorage.setItem('jobId', jobId);
  
      if (!jobId) {
        setPageTitle('Uh Oh!');
        setStatusMessage('We are having some trouble connecting to your bank, or your bank connection is not ready yet. Please try again.');
        setReconsentRequired(true);
        return;
      }
  
      fetchJob(jobId);
    }, [location.search, cdrid]);



  const handleRetry = async () => {
    setIsLoadingRetry(true);
    try {
      const response = await axios.post('https://minerva.sipora.io/api/client/round-up/user/consent',
      {cdrid: cdrid},
      { headers: { 'Content-Type': 'application/json',
        'tenant': config.tenant, 'apikey': config.apiKey
       } }); // Replace with your actual API endpoint
      const consentLink = response.data.consentLink;
      window.location.href = consentLink // Adjust based on your API response structure
    } catch (error) {
      console.error('Error creating consent Link:', error);
    } finally {
      setIsLoadingRetry(false)
    }
  };



  return (
    <ThemeProvider theme={theme}>
    <Container component="main" maxWidth="xs">
 
      <Box display="flex" flexDirection="column" alignItems="center" mt={4} p={2} >
      <Grid container justifyContent="center" mb={2}>
      {/*}
      <Grid item xs={12} justifyContent="center">
      <Box display="flex" justifyContent="center" mb={4}>
      <img src={Logo} alt="Logo"  style={{ width: '70%', height: 'auto'}} />
  </Box></Grid> */}
         <Box display="flex" flexDirection="column" alignItems="center" mt={4} mb={6} style={{ width: '100%'}} >
        <Stepper activeStep={stepperStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
        </Box>
      
      <Typography variant="h4" component="h2" align="center" mt={2} sx={{fontWeight: 'bold'}} gutterBottom>
      {pageTitle}
      </Typography>
      
      <Box display="flex" flexDirection="column" alignItems="center" mt={4} mb={16} style={{ width: '100%'}}>
      <Typography variant="body2" component="h2" align="center"  gutterBottom>
       {statusMessage}
      </Typography>
      {reconsentRequired && (
                <Button
                onClick={handleRetry}
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2, borderRadius: 3, width: "100%"  }}
                disabled={isLoadingRetry}
    
                      
              >
                {isLoadingRetry ? 'Loading...' : 'Retry'}
             
              </Button>
              )}
   
      </Box>

      
    
      </Grid>
      



                    
          <Box mt={2} display="flex" justifyContent="center">
            <Link href="#" variant="body2" sx={{ mr: 3}}>
              Help
            </Link>
            <Link href="#" variant="body2" >
              Terms
            </Link>
            <Link href="#" variant="body2" sx={{ ml: 3}}>
              Privacy
            </Link>
          </Box>
        </Box>
    </Container></ThemeProvider>
  );
};

export default Consented;

export {};