import React, { useState, useEffect } from 'react';
import { TextField, List, ListItem, ListItemText, CssBaseline, Container, Typography, Box, Button, Grid, Link, Stepper, Step, StepLabel } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import Logo from '../../../assets/images/logo.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { theme, style, config } from '../../../config/settings.jsx';
import { steps } from '../../../config/steps.jsx';

const Finished = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingYes, setIsLoadingYes] = useState(false);
  const [isLoadingNo, setIsLoadingNo] = useState(false);
  const navigate = useNavigate();
  const [statusMessage, setStatusMessage] = useState('Checking your bank connection and authorising accounts');
  const [pageTitle, setPageTitle] = useState('Thankyou');
  const location = useLocation();
  const cdrid = sessionStorage.getItem('cdrid');
  const [stepperStep, setStepperStep] = useState(4);


  const handleYes = async () => {

    setIsLoadingYes(true);
    try {
        // Replace this with your API call or function
        const response = await axios.post('https://minerva.sipora.io/api/client/round-up/user/onboarding-complete', {
            cdrid: cdrid, syncBeginningOfMonth: true
        },
        { headers: { 'Content-Type': 'application/json',
          'tenant': config.tenant, 'apikey': config.apiKey
         } }
        );
        navigate('/dashboard');
    } catch (error) {
        console.error('API Error:', error);
    }
};

const handleNo = async () => {
  setIsLoadingNo(true);
  try {
      const response = await axios.post('https://minerva.sipora.io/api/client/round-up/user/onboarding-complete', {
        cdrid: cdrid, syncBeginningOfMonth: false
      },
      { headers: { 'Content-Type': 'application/json',
   'tenant': config.tenant, 'apikey': config.apiKey
       } });
     navigate('/dashboard');
  } catch (error) {
      console.error('API Error:', error);
  }
};


return (
    <ThemeProvider theme={theme}>
    <CssBaseline>
    <Container component="main" maxWidth="xs">
 
      <Box display="flex" flexDirection="column" alignItems="center" mt={4} p={2} >
      <Grid container justifyContent="center" mb={2}>
      {/*}
      <Grid item xs={12} justifyContent="center">
      <Box display="flex" justifyContent="center" mb={4}>
      <img src={Logo} alt="Logo"  style={{ width: '70%', height: 'auto'}} />
  </Box></Grid> */}
         <Box display="flex" flexDirection="column" alignItems="center" mt={4} mb={6} style={{ width: '100%'}} >
        <Stepper activeStep={stepperStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
        </Box>
      
      <Typography variant="h4" component="h2" align="center" mt={2} sx={{fontWeight: 'bold'}} gutterBottom>
      {pageTitle}
      </Typography>
      
      <Box display="flex" flexDirection="column" alignItems="center" mt={4} mb={6} style={{ width: '100%'}}>
      <Typography variant="body2" component="h2" align="center"  gutterBottom>
       Onboarding has been complete:
      </Typography>
      <Typography variant="body2" component="h2" align="center"  gutterBottom>
      Would you like to start your round-ups as of the 1st of this month?
      </Typography>
      </Box>
      <Box display="flex" width="100%" justifyContent="space-between">
           <Box display="flex"  width="49%" >
           <Button 
            onClick={handleNo}
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
             >
            <Typography >
            {isLoadingNo ? 'Loading...' : 'No'}
            </Typography>
         
          </Button>

           </Box>
           <Box display="flex"  width="49%" >
           <Button 
            onClick={(handleYes)}
            type="button"
            fullWidth
            variant="contained"
            color="switchColor"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
            disabled={isLoading}
          >
            <Typography >
            {isLoadingYes ? 'Loading...' : 'Yes'}
            </Typography>
         
          </Button>

           </Box></Box>

      
    
      </Grid>
      



                    
          <Box mt={2} display="flex" justifyContent="center">
            <Link href="#" variant="body2" sx={{ mr: 3}}>
              Help
            </Link>
            <Link href="#" variant="body2" >
              Terms
            </Link>
            <Link href="#" variant="body2" sx={{ ml: 3}}>
              Privacy
            </Link>
          </Box>
        </Box>
    </Container>
    </CssBaseline> </ThemeProvider>
  );
};

export default Finished;

export {};