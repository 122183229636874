import React, { useState, useEffect } from 'react';
import { FormControlLabel, Checkbox, TextField, List, ListItem, ListItemText, Container, Typography, Box, Button, Grid, Link, Stepper, Step, StepLabel } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import Logo from '../../../assets/images/logo.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { theme, style, config } from '../../../config/settings.jsx';
import { steps } from '../../../config/steps.jsx';

const RoundupAccounts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [statusMessage, setStatusMessage] = useState('Checking your bank connection and authorising accounts');
  const [pageTitle, setPageTitle] = useState('Connecting...');
  const location = useLocation();
  const cdrid = sessionStorage.getItem('cdrid');
  const [stepperStep, setStepperStep] = useState(1);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);


  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = async () => {
    try {
      const response = await axios.post('https://minerva.sipora.io/api/client/round-up/user/accounts',
        {userId: cdrid},
        { headers: { 'Content-Type': 'application/json',
         'tenant': config.tenant, 'apikey': config.apiKey
         } }
        ); 
        console.log(response.data.accountsData);
     setAccounts(response.data.accountsData.documents);
     setStepperStep(2); // Adjust based on your API response structure
    } catch (error) {
      console.error('Error fetching clubs:', error);
    }
  };

  const handleCheckboxChange = (event, accountId) => {
    if (event.target.checked) {
      setSelectedAccounts([...selectedAccounts, accountId]);
    } else {
      setSelectedAccounts(selectedAccounts.filter(id => id !== accountId));
    }
  };

  const handleSubmitRoundUpAccounts = async () => {
    const RoundUpAccounts = accounts.map(account => ({
      accountId: account._id,
      activeRoundUpsEnabled: selectedAccounts.includes(account.id),
    }));

  try {
    await axios.post('https://minerva.sipora.io/api/client/round-up/user/set-roundup-accounts', {
      RoundUpAccounts,
    },
    { headers: { 'Content-Type': 'application/json',
      'tenant': config.tenant, 'apikey': config.apiKey
     } });
    navigate('/funding-accounts', { state: { accounts } });
  } catch (error) {
    console.error('Error submitting selected accounts:', error);
  }
};
  

  return (
    <ThemeProvider theme={theme}>
    <Container component="main" maxWidth="xs">
 
    <Box display="flex" flexDirection="column" alignItems="center" mt={4} p={2} >
    <Grid container justifyContent="center" mb={2}>

    {/*}
    <Grid item xs={12} justifyContent="center">
    <Box display="flex" justifyContent="center" mb={4}>
    <img src={Logo} alt="Logo"  style={{ width: '70%', height: 'auto'}} />
    </Box></Grid> */}
         <Box display="flex" flexDirection="column" alignItems="center" mt={4} mb={6} style={{ width: '100%'}} >
      <Stepper activeStep={1} alternativeLabel>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
      </Box>
    
      <Typography variant="h4" component="h2" align="center" mb={2} sx={{fontWeight: 'bold'}} gutterBottom>
      Setting Up
    </Typography>
      
      <Box display="flex" flexDirection="column" alignItems="left" bgcolor="white" mt={1} p={2} borderRadius={4} >
    
        <Typography variant="body2"  align="left" justifyContent="left" gutterBottom >
         Transactions will be tracked from the accounts selected below.  
        </Typography>
        <Box p={1}>
         {accounts.map((account, index) => (
           <FormControlLabel
             key={index}
             control={
             <Checkbox
             onChange={(event) => handleCheckboxChange(event, account.id)}
              />}
             label={
              <Box>
               <Typography variant="body2">{account.name}  {account.accountNumber}</Typography>
            </Box>
             }
            />
          ))}
          </Box>
     </Box>

     
    
      </Grid>
    
   
          <Button
            onClick={handleSubmitRoundUpAccounts}
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: "100%"  }}
                  
          >
          <Typography>
    {isLoading ? 'Loading...' : 'Next'}
  </Typography>
         
          </Button>



                    
          <Box mt={2} display="flex" justifyContent="center">
            <Link href="#" variant="body2" sx={{ mr: 3}}>
              Help
            </Link>
            <Link href="#" variant="body2" >
              Terms
            </Link>
            <Link href="#" variant="body2" sx={{ ml: 3}}>
              Privacy
            </Link>
          </Box>
        </Box>
    </Container></ThemeProvider>
  );
};

export default RoundupAccounts;

export {};