import { createTheme, styled } from '@mui/material/styles';
import { Radio } from '@mui/material';

export const theme = createTheme({
    palette: {
      primary: {
        main: '#010954',
      },
      background: {
        default: '#010954', // Set your desired background colour here
      },
      appbar: {
        default: '#ffffff', // Set your desired background colour here
      },
      switchColor: {
        main: '#33ffbb',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: '#ffffff', // Ensure the body has the same background colour
          },
          secondary: {
            backgroundColor: '#010954', // Ensure the body has the same background colour
          },
          switch: {
            main: '#33ffbb',
          }
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            '&.Mui-active': {
              color: '#33ffbb',
            },
            '&.Mui-completed': {
              color: '#33ffbb',
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '&.Mui-active': {
              color: '#33ffbb',
            },
            '&.Mui-completed': {
              color: '#33ffbb',
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            '&.Mui-active': {
              color: '#33ffbb',
            },
            '&.Mui-completed': {
              color: '#33ffbb',
            },
          },
        },
      },
  }});

export const StyledRadio = styled(Radio)(({ theme }) => ({
    '&.Mui-checked': {
      color: '#33ffbb', // Custom color for checked state
    },
    '& .MuiSvgIcon-root': {
      fontSize: 24, // Adjust the size to match checkbox size
    },
  }));

export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 6,
  p: 4,
};



export const config = {
    apiKey: 'zN6!%2pnLiF3RBnFFU6T3LnNXjMtW5pXTe6nPB7GiqhByxo&QQ@an$go!KhmLEj!UmWS#kcRvsEpvhvXy5okZEi&BNfh9jiN8vDSVHw8^%9Z6@3ppxCxDGw9d%YRaJja',
    tenant: 'GMM_PROD',
    tenantA: 'GMM-PROD',
    
  };