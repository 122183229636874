// src/components/screens/ClubList.tsx
import React, { useState, useEffect } from 'react';
import { TextField, List, ListItem, ListItemText, Container, Typography, Box, Button, Grid, Link, FormControlLabel, FormControl, FormLabel, RadioGroup, Switch, Collapse, CssBaseline, Divider, Toolbar, AppBar, Radio, Checkbox, Modal  } from '@mui/material';
import axios from 'axios';
import Logo from '../../../assets/images/logo.svg';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DrawerMenu from '../Shared/DrawerMenu';
import { styled } from '@mui/material/styles';
import { theme, style, config } from '../../../config/settings.jsx';
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear().toString().slice(-2);
    return `${day}-${month}-${year}`;
  };
  const truncateAndCleanDescription = (description, maxLength) => {
    // Use regex to remove everything before the date and truncate to maxLength
    const cleanedDescription = description.replace(/^.*?\d{2}\/\d{2} /, '');
    if (cleanedDescription.length > maxLength) {
      return `${cleanedDescription.substring(0, maxLength)}...`;
    }
    return cleanedDescription;
  };

const History = () => {
  const cdrid = sessionStorage.getItem('cdrid');
  const [sortedRoundUphistory, setSortedRoundUpHistory] = useState([]);
 

  useEffect(() => {
    const getRoundUpHistory = async () => {
      try {
        const response = await axios.post(
          'https://minerva.sipora.io/api/client/round-up/user/retrieve-history',
          { cdrid },
          { headers: { 'Content-Type': 'application/json',
           'tenant': config.tenant,
             'apikey': config.apiKey
           } }
        );
        const roundUpHistory = sortDebitsByDate(response.data.roundUphistory.documents);
        setSortedRoundUpHistory(roundUpHistory);
        console.log(response)
      }  catch (error) {
        console.error('failed to retrieve total payments', error);
      }
    };
        

    if (cdrid) {
      getRoundUpHistory();
    }
  }, [cdrid]);
 
  const sortDebitsByDate = (debits) => {
    return debits.sort((a, b) => {
      const aDate = new Date(a.createdDate);
      const bDate = new Date(b.createdDate);
      return bDate - aDate; // Sort in descending order
    });
  };

return (
    <ThemeProvider theme={theme}>
    <CssBaseline>
    <Container component="primary" maxWidth="xs"> 
    <AppBar position="static" color="appbar" elevation={0}>
          <Toolbar>
            <DrawerMenu />
            <Typography variant="body" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
              Round-Up History
            </Typography>
          </Toolbar>
        </AppBar></Container>
        <Container component="secondary" maxWidth="xs"> 

  
        <Grid item xs={12} justifyContent="center" height={3}>
    </Grid>
    <Box display="flex" flexDirection="column" alignItems="center" bgcolor="white" mt={2} p={2} borderRadius={4} >
        <Grid container justifyContent="center" mb={6} m={6}>
            <Grid item xs={12} justifyContent="center">
                <Box display="flex" justifyContent="center" >
                    <img src={Logo} alt="Logo"  style={{ width: '60%', height: 'auto'}} />
                </Box>
            </Grid>
        </Grid>
     
    </Box>


   

    


    <Box display="flex" flexDirection="column" alignItems="left" bgcolor="white" mt={2} p={2} borderRadius={4}>
  <Grid container alignItems="left">
    <Box display="flex" alignItems="left" width="100%" mt='10px'>
      <Grid item xs={3}>
        <Typography variant="body2" mt={1} sx={{ fontWeight: 'bold', textAlign: 'left' }}>
          Date
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography variant="body2" mt={1} sx={{ fontWeight: 'bold', textAlign: 'left' }}>
          Description
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2" mt={1} sx={{ fontWeight: 'bold', textAlign: 'right' }}>
          Amount
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2" mt={1} sx={{ fontWeight: 'bold', textAlign: 'right' }}>
          Round-Up
        </Typography>
      </Grid>
    </Box>
  </Grid>
  
  {sortedRoundUphistory.map((debit, index) => (
    <Box key={index} display="flex" justifyContent="space-between" alignItems="center" width="100%" mt='10px'>
      <Typography variant="caption" sx={{ textAlign: 'left', flexGrow: 1, mr: 2 }}>
        {formatDate(debit.createdDate)}
      </Typography>
      <Typography variant="caption" sx={{ textAlign: 'left', flexGrow: 1 }}>
        {truncateAndCleanDescription(debit.description, 50)}
      </Typography>
      <Typography variant="caption" sx={{ textAlign: 'right', flexGrow: 1 }}>
        ${debit.amount}
      </Typography>
      <Typography variant="caption" sx={{ textAlign: 'right', flexGrow: 1, ml: 2, color: 'darkgreen'}}>
        ${((debit.debitAmountCents / 100).toFixed(2))}
      </Typography>
    </Box>
  ))}
</Box>
    <Grid item xs={12} justifyContent="center" height={30}>
    </Grid>
    </Container>
            </CssBaseline>
            </ThemeProvider>
       
  );

}
export default History;

export {};